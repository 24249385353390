import React, {useEffect, useRef, useState} from "react";
//3rd Party
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
// material-ui
import {Box, Grid, IconButton, Tab, Tabs, Tooltip, Typography, useMediaQuery} from "@mui/material";
import {GridToolbarQuickFilter} from "@mui/x-data-grid";
import {DatePicker} from "@mui/x-date-pickers";
import {useTheme} from "@mui/material/styles";
import {RefreshRounded, TuneRounded} from "@mui/icons-material";


//----------------------------  Custom Date Picker Styles Toolbar  --------------------------------------//
export const datePickerStyle = {
    '& .MuiInputBase-input': {backgroundColor: 'white'},
    '& .MuiInputBase-root': {
        height: 40, backgroundColor: 'white',
        '&.Mui-focused': {backgroundColor: 'white'},
        '&:hover': {backgroundColor: 'white'},
    },
    '& .MuiIconButton-root': {
        backgroundColor: 'white',
        '&:hover': {backgroundColor: 'white'},
    },
    '& .MuiOutlinedInput-notchedOutline': {borderColor: 'rgba(0, 0, 0, 0.23)'},
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'theme.palette.primary.main',
    },
};

//----------------------------  Custom Approvals Toolbar  --------------------------------------//
export const ApprovalsToolbar = ({
                                     dateFilter,
                                     isShowDateFilter,
                                     setIsShowDateFilter,
                                     handleDateChange,
                                     loading,
                                 }) => {
    const theme = useTheme();

    const [fromDate, setFromDate] = useState(dayjs(dateFilter.begin).startOf('day'));
    const [toDate, setToDate] = useState(dayjs(dateFilter.end).endOf('day'));

    const firstUpdate = useRef(true);

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        if (fromDate && toDate && fromDate.isValid() && toDate.isValid()) {
            handleDateChange(fromDate, toDate);
        }
    }, [fromDate, toDate]);

    return (
        <Grid container direction="column" sx={{pb: 1, pl: 2, pr: 2, pt: 2}}>
            <Grid item container alignItems="center" justifyContent="space-between">
                <Grid item container xs={6} md={3} alignItems="center">
                    <Typography variant="h2" sx={{mr: 1}}>Approvals</Typography>
                    <Tooltip title="Change the default date selection">
                        <IconButton size="small" onClick={() => setIsShowDateFilter(!isShowDateFilter)}
                                    sx={{color: theme.palette.primary.main, mr: 1}}>
                            <TuneRounded/>
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item xs={6} sm={3} md={4} display="flex" justifyContent="flex-end" alignItems="center">
                    {!loading &&
                        <Tooltip
                            title="Refresh Approvals Table"
                        >
                            <IconButton
                                size="small"
                                onClick={() => handleDateChange(fromDate, toDate)}
                                sx={{color: theme.palette.primary.main, mr: 1}}
                            >
                                <RefreshRounded/>
                            </IconButton>
                        </Tooltip>
                    }
                    <Box sx={{
                        '& .MuiFormControl-root > .MuiInputBase-root': {
                            p: 0.6,
                            border: `1px solid ${theme.palette.grey[300]}`,
                            borderRadius: 2,
                        },
                        '& .MuiFormControl-root > .MuiInputBase-root:after': {
                            display: 'none'
                        },
                        '& .MuiFormControl-root > .MuiInputBase-root:before': {
                            display: 'none'
                        },
                        '& .MuiFormControl-root > .Mui-focused': {
                            border: `1px solid ${theme.palette.primary.main}`
                        },
                        '& .MuiSvgIcon-root': {
                            color: `${theme.palette.primary.main} !important`,
                        },
                    }}>
                        <GridToolbarQuickFilter/>
                    </Box>
                </Grid>
                {isShowDateFilter && (
                    <Grid container spacing={1} sx={{marginTop: '5.5px'}}>
                        <Grid item xs={6} md={2}>
                            <DatePicker
                                sx={datePickerStyle}
                                label="From"
                                value={fromDate}
                                onChange={(value) => setFromDate(value)}
                            />
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <DatePicker
                                sx={datePickerStyle}
                                label="To"
                                value={toDate}
                                onChange={(value) => setToDate(value)}
                            />
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};



ApprovalsToolbar.propTypes = {
    tabValue: PropTypes.number,
    handleTabChange: PropTypes.func,
    dateFilter: PropTypes.shape({
        begin: PropTypes.instanceOf(Date),
        end: PropTypes.instanceOf(Date),
    }).isRequired,
    isShowDateFilter: PropTypes.bool.isRequired,
    setIsShowDateFilter: PropTypes.func.isRequired,
    handleDateChange: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    bankApproval: PropTypes.bool,
};

ApprovalsToolbar.defaultProps = {
    bankApproval: false,
};