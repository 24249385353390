import {Grid} from "@mui/material";
import CustomDropdown from "../form-fields/CustomDropdown";
import CustomFormField from "../form-fields/CustomFormField";

// ============================|| Ach - Form ||============================ //

export const ACHForm = ({values, handleChange, handleBlur, touched, errors}) => {
    const shouldDisplayShortName = values.name.length > 16 || /[^\x00-\x7F]+/.test(values.name);

    return (
        <Grid container spacing={1}>
            {shouldDisplayShortName && (
                <Grid item xs={12} md={6}>
                    <CustomFormField
                        name="shortName"
                        label="Short Name"
                        value={values.shortName}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                    />
                </Grid>
            )}
            <Grid item xs={12}>
                <CustomDropdown
                    name="achType"
                    label="Account Type"
                    value={values.achType}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                    options={[
                        {value: 'dda', label: 'Demand Deposit Account'},
                        {value: 'savings', label: 'Savings'},
                    ]}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <CustomFormField
                    name="aba"
                    label="ABA"
                    value={values.aba}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <CustomFormField
                    name="account"
                    label="Account"
                    value={values.account}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <CustomFormField
                    name="description"
                    label="Description"
                    value={values.description}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                />
            </Grid>

        </Grid>
    );
};
